import React from "react"
import ImageMeta from "../../components/ImageMeta"
function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function OurMission(props) {
  return (
    <>
      <div className="our-mission-stripes">
        {/* <ImageMeta
          cloudName="nuvolum"
          publicId="NALO/DEV/about-our-mission-line"
          width="auto"
          responsive
        /> */}
      </div>

      {/* <div className="gradient-divider"></div> */}

      <section
        className="our-mission"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/nuvolum/image/upload/v1631054795/NALO/DEV/our-mission-hero.jpg')"
        }}>
        <div
          style={{ width: "100%" }}
          className={`columns has-text-centered-tablet`}>
          <div className={`column is-${props.sideColumnIs}`}></div>
          <div
            className="column"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourMission)
            )}></div>
          <div className={`column is-${props.sideColumnIs}`}></div>
        </div>
      </section>

      {/* <div className="gradient-divider"></div> */}

      <div className="our-mission-stripes">
        {/* <ImageMeta
          cloudName="nuvolum"
          publicId="NALO/DEV/about-our-mission-line"
          width="auto"
          responsive
        /> */}
      </div>
    </>
  )
}

export default OurMission
