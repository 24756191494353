import React from "react"
import classNames from "classnames"
import { Transformation } from "cloudinary-react"
import ImageMeta from "../../components/ImageMeta"

import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import NuvoImage from "../../components/NuvoImage"
import TheaterVideo from "@components/Theater/TheaterVideo"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

function AboutVideo(props) {
  if (!props.post.aboutVideo.hasThisSection) return <></>

  const mainClasses = classNames("body-section our-office", {
    "color-back": props.colorBack
  })

  return (
    <section className={mainClasses}>
      {props.post.aboutVideo.text && (
        <div className="columns">
          <div className="column is-4" />
          <div
            className="column mobile-col has-text-centered-tablet"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.aboutVideo.text)
            )}
            style={{marginBottom: 40}}
          />
          <div className="column is-4" />
        </div>
      )}

      {props.post.aboutVideo.youtube && (
        <div className="columns has-text-centered about-main-theater-image">
          <div className="column is-6" />
          <div className="column">
            <TheaterVideo
              buttonClass="contained"
              videoUrl={`https://www.youtube.com/watch?v=${props.post.aboutVideo.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}
              language={props.language}>
              <NuvoImage
                cloudName="nuvolum"
                publicId={props.post.aboutVideo.imageId}
                useAR
                width="auto"
                responsive
              />
            </TheaterVideo>
          </div>
          <div className="column is-6" />
        </div>
      )}
    </section>
  )
}

export default AboutVideo
