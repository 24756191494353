import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ImageMeta from "../../components/ImageMeta"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function TeamMember(props) {
  const [hover, setHover] = useState(false)
  return (
    <div
      className="column is-4"
      key={props.publicId}
      onMouseEnter={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{ position: "relative", top: 0, left: 0 }}>
      <ImageMeta
        cloudName="nuvolum"
        publicId={props.publicId}
        responsive
        width="auto"
        style={{
          opacity: hover ? 0 : 1,
          transition: "opacity .3s ease-in",
          position: "relative",
          top: 0,
          left: 0
        }}
      />
      <ImageMeta
        cloudName="nuvolum"
        publicId={props.publicIdHover}
        responsive
        width="auto"
        style={{
          opacity: hover ? 1 : 0,
          transition: "opacity .3s ease-in",
          position: "absolute",
          top: 0,
          left: 0
        }}
      />
      <div className="has-text-centered team-member-item">
        <h4 className="" style={{ margin: 0, marginBottom: "0" }}>
          {props.staffName}
        </h4>
        <p className="">{props.staffTitle}</p>
      </div>
      {/* <h6 className="image-caption staff staff-name">{props.staffName}</h6>
      <h6 className="image-caption staff staff-title light">
        {props.staffTitle}
      </h6> */}
    </div>
  )
}

TeamMember.propTypes = {
  publicId: PropTypes.string,
  staffName: PropTypes.string,
  staffTitle: PropTypes.string
}

function TeamRow(props) {
  const { language } = props
  const sideColumns = classNames({
    "is-4": props.members.length >= 4
  })
  return (
    <div className={`columns staff-row ${props.last ? "last" : ""}`}>
      <div className={`column ${sideColumns}`}></div>
      {props.members.map(member => {
        return (
          <TeamMember
            key={member.staffMember.imageId}
            publicId={member.staffMember.imageId}
            publicIdHover={member.staffMember.imageIdHover}
            staffName={member.staffMember.staffName}
            staffTitle={
              language === "es"
                ? member.staffMember.spanishTitle
                : member.staffMember.staffTitle
            }
          />
        )
      })}
      <div className={`column ${sideColumns}`}></div>
    </div>
  )
}

TeamRow.propTypes = {
  members: PropTypes.array
}

function MeetOurTeam(props) {
  if (!props.post.meetOurTeam.hasThisSection) return <></>

  var meetOurTeamClasses = classNames({
    "body-section meet-our-team": true,
    "color-back": props.invert
  })

  const members = props.team

  var memberRows = []
  var size = 4

  for (var i = 0; i < members.length; i += size) {
    memberRows.push(members.slice(i, i + size))
  }

  return (
    <section className={meetOurTeamClasses}>
      <div className="columns meet-our-team-heading">
        <div className="column is-4"></div>
        <div
          className="column mobile-col has-text-centered-tablet"
          dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(props.post.meetOurTeam.text)
          )}></div>
        <div className="column is-4"></div>
      </div>

      {props.post.meetOurTeam.showStaff && (
        <>
          {memberRows.map((row, i) => {
            return (
              <React.Fragment key={i}>
                <TeamRow
                  listKey={i}
                  members={row}
                  language={props.language}
                  last={i == memberRows.length - 1 ? true : false}
                />
              </React.Fragment>
            )
          })}
        </>
      )}
    </section>
  )
}

MeetOurTeam.propTypes = {
  invert: PropTypes.bool,
  post: PropTypes.shape({
    meetOurTeam: PropTypes.shape({
      text: PropTypes.string,
      staffImages: PropTypes.array
    }).isRequired
  }).isRequired
}

export default MeetOurTeam
